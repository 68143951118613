/* Custom styles */
.flex {
    flex-wrap: nowrap;
  }
  
  h2 {
    color: inherit; /* Inherit color from parent element */
  }
  
  .bg-indigo-100 {
    background-color: #e0e7ff;
  }
  
  .text-indigo-700 {
    color: #4338ca;
  }
  
  .bg-green-100 {
    background-color: #def7ec;
  }
  
  .text-green-700 {
    color: #047857;
  }
  
  .bg-yellow-100 {
    background-color: #fef9c3;
  }
  
  .text-yellow-700 {
    color: #b45309;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  
  .rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }