.chat {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    width: 300px;  /* Fixed width for alignment */
}

.chat-title {
    color: #2a2a2a;
    margin-bottom: 10px;
}

.chat-detail {
    margin-bottom: 4px;
    font-size: 16px;
    color: #555;
}

.chat-status-active {
    border-color: green;
}

.chat-status-completed {
    border-color: blue;
}

.chat-status-not-started {
    border-color: red;
}