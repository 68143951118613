@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full bg-gray-100 text-gray-600;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply h-full text-gray-600;
}

body::-webkit-scrollbar,
body ::-webkit-scrollbar,
body::-webkit-scrollbar-corner,
body ::-webkit-scrollbar-corner {
  @apply bg-none rounded-2xl rounded-tl-none rounded-bl-none w-2 h-2;
}
body::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  @apply rounded-lg min-h-[24px] border-none;
}
*:hover::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}
*::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  @apply h-full;
}

.svg-full-white path {
  @apply fill-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}

ol,
ul {
  list-style-type: none;
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}

.DraftEditor-root {
  height: auto !important;
}
